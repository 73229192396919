
import Vue from "vue";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { Actions, Mutations } from "@/store/models";
import { companies, setAuthTokenHeader } from "@/api";
import Logo from "@/components/common/Logo.vue";
import { isValidEmail, isValidPassword, isValidPhoneNumber } from "@/utils";
import SignupUserAgreement from "./SignupUserAgreement.vue";

export default Vue.extend({
  name: "SignupTeam",
  components: { Logo, SignupUserAgreement },
  data() {
    return {
      showTerms: true,
      showAgreement: false,
      showSignupForm: false,
      image: require("/src/assets/Vertical-Travel-quarter-container.jpg"),
      token: this.$route.query.token as string,
      request: {
        first_name: "",
        last_name: "",
        user_name: "",
        password: "",
        phone_number: "",
        company: {
          name: "",
        },
      },
      showPassword: false,
      verifyPassword: "",
      showVerifyPassword: false,
    };
  },
  computed: {
    terms: function (): string {
      return this.$store.state.terms;
    },
    agreement: function (): string {
      return this.$store.state.agreement;
    },
  },
  created() {
    this.request.user_name = (this.$route.query.user_email as string)
      .trim()
      .replaceAll(" ", "+");
  },
  methods: {
    termsAccepted() {
      this.showAgreement = true;
      this.showTerms = false;
      setAuthTokenHeader(this.token);
    },
    async agreementAccpeted() {
      this.showSignupForm = true;
      this.showAgreement = false;
    },
    async signupCompany() {
      // trim company name
      this.request.company.name = this.request.company.name.trim();

      if (this.request.company.name.toUpperCase() === "GSSI") {
        this.$store.dispatch(
          Actions.DisplayError,
          "Please enter a valid company name."
        );
        return;
      }

      if (!this.request.first_name) {
        this.$store.dispatch(
          Actions.DisplayError,
          "Please fill in First Name to continue."
        );
        return;
      }
      if (!this.request.last_name) {
        this.$store.dispatch(
          Actions.DisplayError,
          "Please fill in Last Name to continue."
        );
        return;
      }
      if (!this.request.password) {
        this.$store.dispatch(
          Actions.DisplayError,
          "Please fill in Password to continue."
        );
        return;
      }
      if (!this.request.phone_number) {
        this.$store.dispatch(
          Actions.DisplayError,
          "Please fill in Phone Number to continue."
        );
        return;
      }
      if (!this.request.company.name) {
        this.$store.dispatch(
          Actions.DisplayError,
          "Please fill in Company Name to continue."
        );
        return;
      }

      // phone number validation
      this.request.phone_number = this.request.phone_number.replaceAll(" ", "");
      if (!isValidPhoneNumber(this.request.phone_number)) {
        this.$store.dispatch(
          Actions.DisplayError,
          "Phone numbers must follow these format rules: A phone number must start with a plus (+) sign, followed immediately by the country code. A phone number can only contain the + sign and digits. Remove any other characters from a phone number, such as parentheses, spaces, or dashes (-) before you submit the value to the service. For example, a phone number based in the United States must follow this format: +14325551212."
        );
        return;
      }

      if (!isValidEmail(this.request.user_name)) {
        this.$store.dispatch(Actions.DisplayError, "Invalid Email");
        return;
      }

      if (this.request.password !== this.verifyPassword) {
        this.$store.dispatch(Actions.DisplayError, "Password does not match");
        return;
      }

      // password validation
      this.request.password = this.request.password.trim();
      if (!isValidPassword(this.request.password)) {
        this.$store.dispatch(
          Actions.DisplayError,
          `Password validation failed:
          - Must be at least 8 characters
          - Must contain at least 1 number
          - Must contain at least 1 upper case character
          - Must contain at least 1 lower case character
          - Must contain at least 1 special character`
        );
        return;
      }

      try {
        // for local env
        // if (process.env.NODE_ENV === "development") {
        //   const { initialized, msg } = await this.$store.dispatch(
        //     Actions.Init,
        //     "user1@abc.com"
        //   );
        //   this.loggedIn();
        //   return;
        // }
        // else {
        await companies.nlc.singupCompany(this.request);
        this.login();
        // }
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async login() {
      this.$store.commit(Mutations.SetIsLoading, true);

      const authenticationDetails = new AuthenticationDetails({
        Username: this.request.user_name,
        Password: this.request.password,
      });
      const userPool = new CognitoUserPool({
        UserPoolId: process.env.VUE_APP_USER_POOL_ID,
        ClientId: process.env.VUE_APP_CLIENT_ID,
      });
      const cognitoUser = new CognitoUser({
        Username: this.request.user_name,
        Pool: userPool,
      });

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (result) => {
          const { initialized } = await this.$store.dispatch(
            Actions.Init,
            (result.getAccessToken() as any).jwtToken
          );
          if (initialized) {
            this.loggedIn();
          } else {
            this.$store.dispatch(Actions.DisplayError, "Cannot Authorize User");
          }
          this.$store.commit(Mutations.SetIsLoading, false);
        },
        onFailure: (err) => {
          this.$store.dispatch(
            Actions.DisplayError,
            err.message || JSON.stringify(err)
          );
          this.$store.commit(Mutations.SetIsLoading, false);
        },
      });
    },

    loggedIn() {
      this.$router.replace("/signup/invitemember");
    },
  },
});
