
import Vue from "vue";
import { Actions } from "@/store/models";
import { companies } from "@/api";
import Logo from "@/components/common/Logo.vue";

export default Vue.extend({
  name: "SignupUserAgreement",
  components: { Logo },
  props: {
    agreement: String,
  },
  data() {
    return {
      image: require("/src/assets/Vertical-Travel-quarter-container.jpg"),
      imageBottom: require("/src/assets/bottom-pic-landscape.jpg"),
      showDeclinedView: false,
    };
  },
  computed: {},

  methods: {
    async accepted() {
      try {
        await companies.nlc.updateUserAgreement(true);
        this.$emit("agreementAccpeted");
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async declined() {
      try {
        await companies.nlc.updateUserAgreement(false);
        this.showDeclinedView = true;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    gssiSupport() {
      window.open("https://www.geophysical.com/support", "_blank")?.focus();
    },
  },
});
